import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"

import ContactCTABlock from "../components/blocks/ContactCTA"

import SubmenuEnvi from "../components/menus/submenu-envi"


const ENVIPage = () => {

    // using static header image, load here
    const data = useStaticQuery(
        graphql`
      query {
        heroImage: file(relativePath: { eq: "backgrounds/panorama-render.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
            )
          }
        }
      }
    `
    )

    return (
        <Layout>
            <Seo title="ENVI-Marine™ Exhaust Gas Scrubbing Technology" />

            {/* Title banner */}
            <ImgBackground image={data.heroImage}>
                <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-96 lg:flex">
                    <TitleBox
                        title="Game Changing Exhaust Gas Scrubbing Device"
                        caption="ENVI-Marine™"
                    >
                        
                    </TitleBox>
                </div>
            </ImgBackground>

            {/* Content Area */}
            <section className="py-16 lg:py-32 pb-8 lg:pb-16">
                <div className="container">

                    <div className="lg:flex lg:space-x-12">

                        <div className="mb-12 lg:w-3/12">
                            <SubmenuEnvi className="" />
                        </div>

                        <div className="mb-12 lg:w-5/12">
                        <p className="text-2xl">
                            With a system that is arguably the world's most compact for its performance, Pacific Green is able to take on and beat the competition with class winning efficiency and lower running costs.
                        </p>
                        </div>
                    </div>

                    <div className="prose max-w-none">
                        
                    </div>

                </div>
            </section>

            {/* Content Area - with scrubber offset */}
            <section className="bg-primary-50 py-16">
                <div className="container">

                    <div className="lg:flex relative">

                        <div className="mb-12 lg:w-1/2 lg:order-2">
                            <StaticImage src="../images/illustrations/ENVI-Marine--working-diagram.png" alt="PGT global offices map" className="lg:absolute lg:bottom-0 lg:right-0 lg:w-1/2" />
                        </div>

                        <div className="mb-12 lg:w-1/2 lg:order-1">
                            <div className="prose">
                                <p>ENVI-Marine™ systems are fully flexible and can be supplied as open loop, open loop hybrid-ready, and hybrid systems capable of both open and closed mode operation.</p>
                                <p>For vessels running heavy fuel oil, this system offers industry-leading ROI and will clean the exhaust to international emission standards far into the future.</p>
                                <p>The patented design of ENVI-Marine can be installed both internally and externally in a number of flexible configurations and offers real advantages. </p>
                        <p>That’s why ship owners are increasingly turning to Pacific Green to meet the environmental challenges faced by exhaust gas emission controls worldwide.</p>
                            </div>
                            
                        </div>

                    </div>

                    <div className="my-16 grid gap-4 grid-cols-2 lg:grid-cols-4">
                        <div className="bg-primary text-white p-4 rounded"><p>Ultra efficient patented TurboHead™ ‘frothing’ design</p></div>
                        <div className="bg-primary text-white p-4 rounded"><p>Compact, flexible rectangular shape creates smallest possible footprint</p></div>
                        <div className="bg-primary text-white p-4 rounded"><p>Reassurance of global retro-fit and service network</p></div>
                        <div className="bg-primary text-white p-4 rounded"><p>No moving parts or media to replace in the scrubber</p></div>
                        <div className="bg-primary text-white p-4 rounded"><p>Simple fitting process allows one device to deal with multiple inlets</p></div>
                        <div className="bg-primary text-white p-4 rounded"><p>Rapid payback period</p></div>
                        <div className="bg-primary text-white p-4 rounded"><p>Every project is customized, flexible and commercially driven</p></div>
                        <div className="bg-primary text-white p-4 rounded"><p>Future-proof technology: Long-term compliance</p></div>
                    </div>

                </div>
            </section>

            {/* Content Area */}
            <section className="py-16 lg:py-32 lg:pb-16">
                <div className="container">

                    <div className="lg:flex lg:space-x-12 lg:justify-center text-center">
                        <div className="mb-12 lg:w-1/2 prose">
                            <StaticImage src="../images/general/render-naked.jpg" alt="ENVI-Marine Naked Scrubber" className="mb-6" />
                            <h2 className="text-3xl mb-4">ENVI-Marine™ Naked Installation</h2>
                            <p>ENVI-Marine (N) is an external installation adapted to fit adjacent to an existing exhaust stack.</p>
                        </div>
                        <div className="mb-12 lg:w-1/2 prose">
                            <StaticImage src="../images/general/render-enclosed.jpg" alt="ENVI-Marine Enclosed Scrubber" className="mb-6" />
                            <h2 className="text-3xl mb-4">ENVI-Marine™ Enclosed Installation</h2>
                            <p>ENVI-Marine (E) is an enclosed installation adapted to fit within an existing exhaust stack.</p>
                        </div>
                    </div>


                </div>
            </section>

            {/* CTA contact block */}
            <section className="py-16 lg:py-32 bg-primary-50">
                <ContactCTABlock />
            </section>

        </Layout>
    )
}

export default ENVIPage
